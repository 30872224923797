@import '../theme.scss';

.skills {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;
}

@media (min-width: 768px) {
  .skills {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media (min-width: 1280px) {
  .skills {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1rem;
  }
}

.column {
  position: relative;
  margin: 0 3px 1rem;
}

.divider {
  min-width: 1px;
  min-height: 1px;
  background: $primary;
  box-shadow: 0 0 5px $primary;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .divider {
    min-height: 100%;
    margin: 0 0.5rem 1rem;
  }
}

@media (min-width: 1280px) {
  .divider {
    margin: 0;
  }
}
