@import '../theme.scss';

.button {
  border: 1px solid $secondary;
  background-color: $negative;
  transition: all 100ms linear;
}

.button:disabled {
  pointer-events: none;
}

.button:hover:not(:disabled),
.button[data-focus-visible-added]:not(:disabled) {
  filter: drop-shadow(0 0 5px $secondary);
  box-shadow: 0 0 5px $secondary;
}

.button.available {
  background-color: $neutral;
}

.button:focus {
  outline: none;
}

.button:disabled {
  background: $negative;
}
