@import '../theme';

.alert {
  position: fixed;
  left: 0.5rem;
  top: 2.2rem;
  width: calc(100% - 1rem);
}

@media (min-width: 768px) {
  .alert {
    top: unset;
    bottom: 2rem;
    display: flex;
    justify-content: center;
  }
}

.text {
  width: 100%;
  background: #000000dd;
  box-shadow: 0 0 0.3rem #{$secondary}dd;
  color: $text;
  padding: 15px 10px;
  border-radius: 10px;
  text-align: center;
  transition: linear all;
}

@media (min-width: 768px) {
  .text {
    width: initial;
  }
}
