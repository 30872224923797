$background: #0d152d;
$primary: #17d2ff;
$secondary: #94bade;
$negative: #ee00004d;
$neutral: #0094ff4d;
$neutral-dark: #0094ff1a;
$positive: #43d937;
$select: #fdc855;
$error: #d96548;
$text: #c1d5db;
