@import '../theme';

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background: $background;
}

@media (min-width: 1280px) {
  .header {
    position: static;
  }
}

.control {
  display: none;
  margin-left: 1rem;
}

@media (min-width: 421px) {
  .control {
    display: block;
  }
}
